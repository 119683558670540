<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> QRCODE by PO </CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Kode PO"
									placeholder="Enter Kode PO"
									v-model="kode"
								/>
							</CCol>
							<CCol md="4" class="mt-4">
								<button
									:disabled="products.length < 1"
									class="btn mr-1 btn-secondary"
									@click="getItems"
								>
									REFRESH
								</button>
								<button
									:disabled="products.length < 1"
									class="btn mr-1 btn-success"
									@click="print(1)"
								>
									PRINT 1
								</button>

								<button
									:disabled="products.length < 1"
									class="btn btn-info"
									@click="print(3)"
								>
									PRINT 3
								</button>
							</CCol>
						</CRow>
						<CRow>
							<CCol md="12">
								<table v-show="!loading" class="table table-bordered">
									<thead>
										<tr>
											<th>Kode</th>
											<th>Product</th>
											<th>Size</th>
											<th>Jumlah</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="p.kode" v-for="p in products">
											<td>{{ p.kode }}</td>
											<td>{{ p.product.nama }}</td>
											<td>{{ size[p.size].nama }}</td>
											<td>
												<input
													@focus="$event.target.select()"
													type="number"
													v-model.number="p.jumlah"
												/>
											</td>
										</tr>
										<tr>
											<td colspan="3" class="text-right">TOTAL</td>
											<td>
												{{ total() }}
												<label
													@click="kosongkan"
													style="cursor: pointer"
													class="text-danger ml-3"
												>
													(KOSONGKAN)
												</label>
											</td>
										</tr>
									</tbody>
								</table>
								<div v-show="loading" class="text-center">
									<ellipsis-loader :color="'#54f1d2'" />
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol md="12">
								<button
									:disabled="products.length < 1"
									class="btn mr-1 btn-success"
									@click="print(1)"
								>
									PRINT 1
								</button>

								<button
									:disabled="products.length < 1"
									class="btn btn-info"
									@click="print(3)"
								>
									PRINT 3
								</button>
							</CCol>

							<div class="d-none">
								<div id="print3">
									<div
										v-for="(p, j) in products2"
										:key="j"
										class="pagebreak"
										style="
											height: 16.5cm;
											width: 21cm;
											background-color: #fff;
											padding-top: 0.9cm;
											padding-bottom: 1cm;
											padding-left: 0.6cm;
										"
									>
										<div
											style="
												height: 14.6cm;
												width: 20.3cm;
												background-color: #fff;
											"
										>
											<template v-for="i in [0, 1, 2, 3]">
												<div :key="i">
													<div
														:style="setStylePrint(i, k)"
														:key="k"
														v-for="k in [0, 1, 2]"
														v-if="logSaja(p, i, k)"
													>
														<div
															style="
																display: table-cell;
																margin-top: 0.3cm;
																padding: 0.4cm 0.1cm 0.2cm 0.3cm;
															"
														>
															<qrcode-vue
																:render-as="'svg'"
																:value="p[i + i * 2 + k].kode"
																:size="80"
																level="H"
															/>
														</div>
														<div
															style="
																display: table-cell;
																margin-left: 0cm;
																text-align: center;
																vertical-align: middle;
															"
														>
															<span
																style="
																	padding: 2px 2px 2px 0px;
																	font-weight: bold;
																	font-size: 7pt;
																	line-height: 0%;
																"
															>
																{{ p[i + i * 2 + k].kode }}
															</span>
															<br />

															<div
																style="
																	padding: 1px;
																	font-size: 7pt;
																	font-weight: bold;
																	line-height: 109%;
																"
															>
																{{ p[i + i * 2 + k].product.nama }}
															</div>
															<span
																style="
																	padding: 0px;
																	font-size: 7pt;
																	font-weight: bold;
																	line-height: 0%;
																"
															>
																{{
																	size[p[i + i * 2 + k].size].nama +
																	" / " +
																	size[p[i + i * 2 + k].size].alias
																}}
															</span>
														</div>
													</div>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<div class="d-none">
			<div id="print1">
				<template v-for="(p, j) in products">
					<div
						style="
							display: table;
							height: 30mm;
							width: 50mm;
							border-style: none;
							text-align: left;
						"
						class="pagebreak"
						:key="p.kode + i"
						v-for="i in parseInt(p.jumlah)"
					>
						<div
							style="
								width: 20mm;
								padding-top: 0mm;
								padding-right: 0mm;
								vertical-align: middle;
								display: table-cell;
								text-align: center;
								background: #ffffff;
							"
						>
							<qrcode-vue
								:render-as="'svg'"
								:value="p.kode"
								:size="82"
								level="H"
							/>
						</div>
						<div
							style="
								width: 30mm;
								vertical-align: middle;
								display: table-cell;
								text-align: center;
							"
						>
							<span
								style="
									padding: 2px 2px 2px 0px;
									font-size: 8pt;
									font-weight: bold;
								"
							>
								{{ p.kode }}
							</span>
							<br />

							<span style="padding: 0px; font-size: 8pt; font-weight: bold">
								{{ size[p.size].nama + " / " + size[p.size].alias }}
							</span>

							<h3 style="padding: 0px; font-size: 8pt; font-weight: bold">
								{{ p.product.nama }}
							</h3>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { debounce, size } from "@/plugins/utils";
import { mapState } from "vuex";

export default {
	components: {
		QrcodeVue,
	},
	computed: {
		...mapState("appinfo", ["name"]),
	},
	data() {
		return {
			kode: "PD0",
			products: [],
			products2: {},
			size,
			loading: false,
		};
	},
	watch: {
		kode: debounce(function (val) {
			if (val.length == 7) this.getItems();
			else this.products = [];
		}, 700),
		products: {
			deep: true,
			handler(val) {
				this.setProduct2();
			},
		},
	},
	methods: {
		setProduct2() {
			this.products2 = {};

			//console.log(this.products);

			const jmlGroup = Math.ceil(this.total() / 12);
			for (let i = 0; i < jmlGroup; i++) {
				this.products2["product_" + i] = [];
			}

			let gr = 0;
			for (let i = 0; i < this.products.length; i++) {
				const el = this.products[i];
				for (let j = 0; j < el.jumlah; j++) {
					this.products2["product_" + gr].push(el);

					if (this.products2["product_" + gr].length >= 12) {
						gr++;
					}
				}
			}
		},
		async getItems() {
			this.loading = true;
			this.products = [];
			const { data } = await this.$http.get("product-item", {
				params: { filter: this.kode },
			});
			this.products = data.data.data;
			this.loading = false;
		},
		print(i) {
			const el = "print" + i;
			const prtHtml = document.getElementById(el).innerHTML;
			const WinPrint = window.open(
				"",
				"",
				"left=0,top=0,width=800px,height=1000px,toolbar=0,scrollbars=0,status=0"
			);
			WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style>
						.pagebreak { page-break-after: always; }
						body {
							font-family: sans-serif;
							-webkit-print-color-adjust: exact !important;
						}
						div {background-color: #fffff; !important;}

						</style>
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);
			//WinPrint.document.close();
			WinPrint.focus();
			WinPrint.print();
			WinPrint.close();
		},
		total() {
			let total = 0;
			for (let i = 0; i < this.products.length; i++) {
				const el = this.products[i];
				total += el.jumlah;
			}
			return total;
		},
		kosongkan() {
			for (let i = 0; i < this.products.length; i++) {
				this.products[i].jumlah = 0;
			}
		},
		setStylePrint(i, k) {
			let style = `height: 3.3cm;
									width: 6.5cm;
									background-color: #ffffff;
									margin-right: 0.4cm;
									margin-bottom: 0.61cm;
									display: inline-block;
									float: left;
									border-style: thin;
									border-width: thin;
									`;

			if (i == 3) {
				style += "margin-bottom: 0cm;";
			}
			if (k == 2) {
				style += "margin-right: 0cm;";
			}

			return style;
		},
		logSaja(p, i, k) {
			//console.log(p[i + i * 2 + k]);
			return p[i + i * 2 + k] ? true : false;
		},
	},
};
</script>
